var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("gov-heading", { attrs: { size: "l" } }, [
        _vm._v(" Registration - step 2 of 5 ")
      ]),
      _c("gov-back-link", { attrs: { to: { name: "register-index" } } }, [
        _vm._v(" Back ")
      ]),
      _c("gov-heading", { attrs: { size: "l" } }, [
        _vm._v(
          "\n    Is your organisation right for " +
            _vm._s(_vm.appName) +
            "?\n  "
        )
      ]),
      _c("gov-body", [
        _vm._v(
          "\n    We have some criteria for organisations that are listed on the site.\n  "
        )
      ]),
      _c(
        "gov-form-group",
        [
          _c("gov-label", [
            _c("strong", [
              _vm._v(
                "\n        The organisation that you want to be listed on " +
                  _vm._s(_vm.appName) +
                  " is:\n      "
              )
            ])
          ]),
          _c("gov-hint", [_vm._v(" Tick all that apply. ")]),
          _c(
            "gov-checkboxes",
            [
              _c("gov-checkbox", {
                attrs: {
                  value: _vm.form.organisation_types.includes("community"),
                  id: "organisation_types.community",
                  name: "organisation_types",
                  label:
                    "Charity, voluntary or community group, faith group or social enterprise"
                },
                on: {
                  input: function($event) {
                    return _vm.onInput("community")
                  }
                }
              }),
              _c("gov-checkbox", {
                attrs: {
                  value: _vm.form.organisation_types.includes("council"),
                  id: "organisation_types.council",
                  name: "organisation_types",
                  label:
                    "A Council or other statutory service, e.g. services delivered by the NHS or local CCG)"
                },
                on: {
                  input: function($event) {
                    return _vm.onInput("council")
                  }
                }
              }),
              _c("gov-checkbox", {
                attrs: {
                  value: _vm.form.organisation_types.includes("commercial"),
                  id: "organisation_types.commercial",
                  name: "organisation_types",
                  label:
                    "A commercial provider offering services to local residents that support health, wellbeing and/or community for free or a reasonable charge"
                },
                on: {
                  input: function($event) {
                    return _vm.onInput("commercial")
                  }
                }
              }),
              _c("gov-checkbox", {
                attrs: {
                  value: _vm.form.organisation_types.includes(
                    "commercial_contracted"
                  ),
                  id: "organisation_types.commercial_contracted",
                  name: "organisation_types",
                  label:
                    "A commercial service that is contracted or spot purchased under a commissioning arrangement with the local Council, intended to improve the health, wellbeing or independence of local residents"
                },
                on: {
                  input: function($event) {
                    return _vm.onInput("commercial_contracted")
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "gov-button",
        {
          attrs: {
            start: "",
            to: { name: "register-new-step3" },
            disabled: _vm.form.organisation_types.length === 0
          }
        },
        [_vm._v("\n    Next\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }